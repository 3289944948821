<template>
  <div class="xmsbs">
    <div class="header">
      <div class="h1">项目申报</div>
      <div class="h2">智能匹配   一键办理</div>
    </div>
    <div class="flex content">
      <div class="left flex-1 ">
        <div class="title">通知公告<span @click="gotolist()">查看更多<i class="el-icon-arrow-right"></i></span></div>
        <div>
          <div class="flex news1"  v-for="(item,index) in items" @click="gotoxq(item)" :key="index">
            <div class="type"></div>
            <div class="nei flex-1 text-overflow">{{item.title}}</div>
            <div  class="time">{{item.createTime}}</div>
          </div>
        </div>
      </div>
      <div  class="right">
        <el-image :src="linkBanner" fit="fill"></el-image>
      </div>
    </div>
    <div class="title1 flex">
<!--      <span class="flex-1"></span>黄骅市促进产业高质量发展专项政策<span class="flex-1"></span>-->
      <el-carousel class="flex-1" :interval="4000" type="card" :autoplay="false" indicator-position="none" height="250px">
        <el-carousel-item v-for="item in supportThemeList" :key="item">
          <el-image :src="item.image" fit="fill"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="zhuti"   style="background: #fff">
      <avue-search :option="option"   @change="loadlist(true)"
                   v-model="form">
        <template #zdy="{}">
          <el-tag>自定义</el-tag>
        </template>
      </avue-search>
      <div style="text-align: right;padding: 0 20px 20px 0">
        <el-button type="primary" size="mini" @click="loadlist(true)">筛选</el-button>
        <el-button  size="mini" @click="reset">重置</el-button>
      </div>
    </div>
    <div class="zhuti">
      <div class="news" v-for="(ar,index) in arr" :key="index" @click="gotoxmsb(ar)">
        <div class="title"><i class="yuan"></i>{{ar.title}}</div>
        <div class="biao">
          <span class="biaoqian" v-for="(bi,index) in ar.labels.split(',')">{{bi}}</span>
        </div>
        <div class="status">
          <span class="st1" :class="{red:ar.state=='进行中'}">{{ar.state!='进行中'?ar.state:'剩余'+ar.days+'天'}}</span>
<!--          <el-button type="danger">申报入口</el-button>-->
        </div>
        <div class="di">
          <div class="">管理部门:{{ar.createDeptName}}</div>
          <div class="">申报起止时间:{{ar.startTime}}至{{ar.endTime}}</div>
        </div>
      </div>
    </div>
    <div class="fenye">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="page.current"
          :page-sizes="[10, 50, 100, 200]"
          :page-size="page.size"
          :total="page.total">
      </el-pagination>
    </div>
  </div>

</template>

<script>
import gxjyheader from "../gxjy/gxjyheader";
import { fiveyears } from '../../utils/index'
import {policyprojectindex, policyprojectpage} from "../../request/moudle/zcfw";
export default {
  name: "zchj",
  data(){
    return {
      form: {
        levelId:'',
        industryId:'',
        labelId:''
      },
      page:{
        current:1,
        size:10,
        total:0
      },
      option: {
        column: [ {
          label: '政策级别',
          prop: 'levelId',
          props: {
            label: 'title',
            value: 'id',
          },
          multiple: false,
          dicData:[],
        },
          {
            label: '行业分类',
            dicData:[],
            prop: 'industryId',
            multiple: false,
            props: {
              label: 'name',
              value: 'id'
            },
          },
          {
            label: '项目标签',
            dicData:[],
            prop: 'labelId',
            multiple: false,
            props: {
              label: 'title',
              value: 'id'
            },
          },
          {
            label: '项目状态',
            dicData:[{
              label: '全部',
              value: ''
            }, {
              label: '未开始',
              value: '未开始'
            }, {
              label: '进行中',
              value: '进行中'
            }, {
              label: '已结束',
              value:'已结束'
            }],
            prop: 'state',
            multiple: false,
          },
         ]
      },
      keyword:'',
      pageNo:1,
      total:1,
      arr:[],
      supportThemeList:[],
      items:[],
      datas:'',
      linkBanner:''
    }
  },
  created() {
    this.loadconfig()
    this.loadlist()
  },
  methods:{
    gotolist(item){
      this.$router.push({
        path:'/parkgonggaolist',
        query:{
          type:'park-project-index-top-notice'
        }
      })
    },
    gotoxq(item){
      this.$router.push({
        path:'/parkgonggaoxq',
        query:{
          id:item.policyId
        }
      })
    },
    gotoxmsb(item){
      this.$router.push({
        path:'/parkxmsbxq',
        query:{
          id:item.id,
          activeName:'0'
        }
      })
    },
    loadconfig(){
      this.$api.park.policyprojectindex().then((res)=>{
        this.datas=res.data
        this.linkBanner=this.datas.linkBanner.image || ''
        this.supportThemeList=res.data.supportBannerList
        this.items=res.data.noticeList.splice(0,5)
        const levelId=this.findObject(this.option.column, 'levelId')
        const industryId=this.findObject(this.option.column, 'industryId')
        const labelId=this.findObject(this.option.column, 'labelId')
        levelId.dicData=res.data.projectLevelFilterList
        levelId.dicData.unshift({ title: '不限', id: '' })
        industryId.dicData=res.data.industryFilterList
        industryId.dicData.unshift({ name: '不限', id: '' })
        labelId.dicData=res.data.labelFilterList
        labelId.dicData.unshift({ title: '不限', id: '' })
      })
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      const obj={
        ...this.form
      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.park.policyprojectpage(obj).then((res)=>{
        this.arr=res.data.records
        this.page.total=res.data.total
      })
    },
    reset(){
      for (const val of this.option.column){
        if(val.multiple){
          this.form[val.prop]=['']
        }else {
          this.form[val.prop]=''
        }
      }
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
  }
}
</script>

<style lang="scss" scoped>
.title1s{
}

.fenye{
  text-align: center;
  padding: 30px 0;
}
.header{
  height: 180px;
  background: url("../../assets/park/parkxmsb.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.content{
  width: 1200px;
  margin: 0 auto;
  margin-top: 62px;
  .right{
    color: #fff;
    width: 535px;
    height: 320px;
  }
  .left{
    margin-right: 25px;
    border: 1px solid #859BB2;
    color: #fff;
    .title{
      background: linear-gradient(0deg,#0078F2,#00BAFF);
      font-size: 18px;
      font-weight: 500;
      padding: 13px 26px;
      span{
        float: right;
        font-size: 14px;
        margin-top: 4px;
      }
    }
    .news1{
      background: #fff;
      color: #4C4C4C ;
      font-size: 14px;
      padding: 17px 16px;
      border-bottom: 1px dashed  #4C4C4C;
      cursor: pointer;
      width: 635px;
      .type{
        width: 12px;
        height: 12px;
        margin: 4px 10px 0 10px;
        border-radius: 50%;
        background: #A5ACB9;
      }
      .time{
        width: 160px;
        font-size: 12px;
        text-align: right;
      }
    }
    .news:nth-last-child(1){
      border-bottom: none;
    }
  }
  .right{

  }
}
.zhuti{
  width: 1200px;
  margin:30px auto;
  .sxx{
    background: #fff;
    padding: 20px;
  }
  .news{
    background:linear-gradient(0deg,#FFFFFF,#EEF1F5);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 55px;
    padding: 20px;
    color: #3B3E45;
    cursor: pointer;
    .title{
      font-size: 18px;
      margin-bottom: 10px;
    }
    .biao{
      padding-left: 27px;
      color: #fff;
      margin: 20px 0 0px 0;
    }
    .status{
      text-align: right;
      margin-right: 30px;
      font-size: 16px;
    }
    .st1{
      margin-right: 40px;
    }
    .red{
      color: red;
    }
    .biaoqian{
      background:linear-gradient(0deg,#0078F2,#00BAFF);
      padding: 2px 8px;
      margin-right: 10px;
    }
    .yuan{
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #A5ACB9;
      margin-right: 15px;
    }
    .di{
      font-size: 14px;
      padding-left: 27px;
      color: #6D6D6D;
      line-height: 30px;
    }
  }
}
.title1{
  width: 1200px;
  margin:40px auto;
  margin-top: 60px;
  position: relative;
  font-size: 20px;
  font-weight:550;
  color:#000000 ;
  span{
    border-top: 1px solid #000000;
    margin: 0 15px;
    margin-top: 14px;
  }
}
</style>